<template>
    <div class="text-center">
      <v-dialog
        v-model="newAccount"
        persistent
        width="60vw"
        class="add-plant-form"
      >
        <v-card class="card-form">
          <v-card-title class="yellowPlexi form-title">Add New Account</v-card-title>
          <v-form validate-on="submit" @submit.prevent="submit" class="BMSForm">
            <v-text-field
              density="comfortable"
              v-model="apiName"
              label="API Name"
              type="text"
              class="input-field"
            ></v-text-field>
            <v-text-field
              density="comfortable"
              v-model="username"
              label="Username"
              type="text"
              class="input-field"
            ></v-text-field>
            <v-text-field
              density="comfortable"
              v-model="password"
              label="Password"
              type="password"
              class="input-field"
            ></v-text-field>
            <v-row>
              <v-col cols=6>
                <v-btn type="button" variant="tonal" @click="newAccount=false"  block class="mt-2">Cancel</v-btn>
              </v-col>
              <v-col cols=6>
                <v-btn
                  type="submit"
                  variant="tonal"
                  block
                  class="mt-2"
                  :loading="dialog"
                >
                  Add</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-row>
        <v-col cols=4>
        </v-col>
        <v-col cols=4>
          <v-card-subtitle class="yellowPlexi form-subtitle">Select Account</v-card-subtitle>
        </v-col>
        <v-col cols=3>
        </v-col>
        <v-col cols=1>
          <v-icon class="yellowPlexi add-plant" @click="addNewAccount" >mdi-plus-box-outline</v-icon>
        </v-col>
      </v-row>
        <v-list
          width="100%"
        >
          <v-row>
            <v-col cols=12>
              <v-card-text>
                <v-text-field
                  v-model="searchTextAccounts"
                  single-line
                  density="compact"
                  label="Filter accounts"
                  append-inner-icon="mdi-filter"
                  class="filter-box"
                ></v-text-field>
              </v-card-text>
            </v-col>
          </v-row>
          <v-list-item-title>
          <v-row >
              <v-col cols=1>

              </v-col>
              <v-col cols =2>
                Name
              </v-col>
              <v-col cols =2>
                User
              </v-col>
              <v-col cols =2>
                Version
              </v-col>
              <v-col cols =2>
                Active
              </v-col>
              <v-col cols =2>
                Date Created
              </v-col>
            </v-row>
          </v-list-item-title>
          <v-list-item class="items"
            v-for="account in filteredaccounts"
            :key="account.uuid"
          >
            <v-row @click="clickAccount(account.uuid)">
              <v-col cols=1>
                <v-icon class="yellowPlexi icon-provider"><i class="fas fa-chevron-right"></i></v-icon>
              </v-col>
              <v-col cols =2 class="col-account">
                <div class ="api-text yellowPlexi pointer" >
                {{ account.api_name }}
                </div>
              </v-col>
              <v-col cols =2>
                <div class ="api-text yellowPlexi pointer" >
                {{ account.api_user }}
                </div>
              </v-col>
              <v-col cols =2>
                <div class ="api-text yellowPlexi pointer" >
                {{ account.version }}
                </div>
              </v-col>
              <v-col cols =2>
                <div class ="yellowPlexi pointer " >
                 <v-checkbox
                v-model="account.is_active"
                density="compact"
                class="yellowPlexi api-check"
                />
                </div>
              </v-col>
              <v-col cols =2>
                <div class ="api-text yellowPlexi pointer" >
                {{ epochToDate(account.created_at)}}
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>

      <v-btn type="button" variant="tonal" @click="cancel"  block class="mt-2">Cancel</v-btn>
    </div>

</template>

<script>

export default {
  name: 'AddPlantCredentials',

  props: {
    accounts: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    searchTextAccounts: '',
    newAccount: false,
    username: null,
    password: null,
    apiName: null

  }),
  computed: {

    filteredaccounts () {
      const filterText = this.searchTextAccounts.toLowerCase().trim()
      if (!filterText) {
        return this.accounts
      } else {
        return this.accounts.filter((account) =>
          account.api_name.substring(0, 6).toLowerCase().includes(filterText)
        )
      }
    }
  },
  methods: {
    cancel () {
      this.$store.dispatch('setElement', { path: 'plantRegistration.showForm', value: false })
    },
    clickAccount (uuid) {
      this.$store.dispatch('setElement', { path: 'plantRegistration.addPlantCredentials', value: false })
      this.$store.dispatch('setElement', { path: 'plantRegistration.addPlantSelected', value: true })
      this.$store.dispatch('setElement', { path: 'plantRegistration.accountSelected', value: uuid })
    },
    epochToDate (epoch) {
      const date = new Date(epoch)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}/${month}/${day}`
    },
    addNewAccount () {
      this.newAccount = true
    },
    async submit (event) {
      this.newAccount = false
      const uuid = window.sessionStorage.getItem('uuid')
      const brand = this.$store.state.plantRegistration.provider
      console.log(this.username, this.password, this.apiName, uuid, brand)
      this.$emit('getAccounts')
      // setTimeout(() => (this.sendCredentials()), 1000)
    }
  }
}
</script>

<style scoped>
.v-info-title{
  font-size: 17px;
  margin-top:-15px;
}
.v-info-text{
  margin-top:2px!important;
}

.v-info-combo{
  margin-top:-10px!important;
  margin-bottom: -20px;
}

.card-info{
  margin-top:15px;
}
.info{
  margin-top: 10px;
}
.row-info{
  margin-bottom: -16px;
}
.BMSForm{
  padding: 20px;

}
.icon-dialog{
  padding: 40px;
margin-top: 0px;
}
.input-field{
  height: 70px;
}
.form-subtitle{
  font-size: 20px;
}
.icon-account{
  margin-top:5px;
  margin-left: 16px;
}
.api-text{
  font-size: 15px;
}
.api-check{
    display: flex;
  justify-content: center;
  align-items: center;
}
.add-plant{
  margin-left:17px;
  color:#f4c020!important;
  cursor: pointer;
}
.form-title{
  padding: 20px;
}
</style>
